import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types"; 

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 1024 1024" {...props}>

<path fill="#1DD23F" d="M420.42,243.59H122.35c-14.61,0-26.45,11.84-26.45,26.45v104.11c0,14.07,11.41,25.47,25.47,25.47
	h98.71v353.46c0,14.07,11.41,25.47,25.47,25.47h108.27c14.07,0,25.47-11.41,25.47-25.47V399.62h41.12c42.36,0,76.7-34.34,76.7-76.7
	v-2.62C497.12,277.93,462.78,243.59,420.42,243.59z"/>
<path fill="#1DD23F" d="M528.96,778.55h111.45c14.07,0,25.47-11.41,25.47-25.47v-57.32v-34.5V455.35v-1.59h0.08
	c0.85-26.52,22.55-47.76,49.28-47.76c27.26,0,49.36,22.1,49.36,49.36s-22.1,49.36-49.36,49.36c-0.54,0-1.06-0.06-1.59-0.08v162.44
	c0.53,0,1.06,0.04,1.59,0.04C832.19,667.1,927,572.3,927,455.35s-94.81-211.76-211.76-211.76c-116.42,0-210.85,93.95-211.71,210.16
	c0,0.53-0.04,1.06-0.04,1.59v297.73C503.49,767.15,514.9,778.55,528.96,778.55z"/>
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="665.8884" y1="561.2065" x2="713.6528" y2="561.2065">
<stop  offset="0" style={{ stopColor: '#1DD23F', stopOpacity: 0 }} />
<stop  offset="1"  style={{ stopColor: '#1DD23F' }} />
</linearGradient>
<path fill="url(#SVGID_1_)" d="M665.89,455.35v205.91c15.35,3.67,31.33,5.68,47.76,5.8V504.63
	C687.14,503.78,665.89,482.07,665.89,455.35z"/> 
    </Svg>
  );
};

export default Icon;
 