import { useRef, RefObject, useCallback, useState, useMemo, useEffect } from 'react'
import {
  Box,
  Button,
  Flex,
  InjectedModalProps,
  LinkExternal,
  Input,
  Message,
  Skeleton,
  Text,
  CopyAddress,
} from '@pancakeswap/uikit'
import { ChainId, WNATIVE } from '@pancakeswap/sdk'
import { FetchStatus } from 'config/constants/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@pancakeswap/localization'
import useAuth from 'hooks/useAuth'
import useNativeCurrency from 'hooks/useNativeCurrency'
import useTokenBalance, { useGetInviteBalance } from 'hooks/useTokenBalance'
import { ChainLogo } from 'components/Logo/ChainLogo'

import { getBlockExploreLink, getBlockExploreName } from 'utils'
import { formatBigNumber, getFullDisplayBalance } from '@pancakeswap/utils/formatBalance'
import { useBalance } from 'wagmi'
import { useCanUseCode, useGetCodeByAddress } from 'hooks/useInvite'
import { useBindCodeCallback } from 'hooks/useBindCallback' 

const COLORS = {
  ETH: '#627EEA',
  BNB: '#14151A',
}

interface WalletInfoProps {
  hasLowNativeBalance: boolean
  switchView: (newIndex: number) => void
  onDismiss: InjectedModalProps['onDismiss']
}

 




const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowNativeBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account, chainId, chain } = useActiveWeb3React()
  const isBSC = chainId === ChainId.BSC
  const bnbBalance = useBalance({ address: account, chainId: ChainId.BSC })
  const nativeBalance = useBalance({ address: account, enabled: !isBSC })
  const native = useNativeCurrency()
  const wNativeToken = !isBSC ? WNATIVE[chainId] : null
  const wBNBToken = WNATIVE[ChainId.BSC]
  const { balance: wNativeBalance, fetchStatus: wNativeFetchStatus } = useTokenBalance(wNativeToken?.address)
  const { balance: wBNBBalance, fetchStatus: wBNBFetchStatus } = useTokenBalance(wBNBToken?.address, true)
  const { balance: inviteBalance, fetchStatus: inviteFetchStatus } = useGetInviteBalance()
  const { logout } = useAuth()

  const [newCode, setNewCode] = useState<string>('')
  const checkcode=useCanUseCode()
  const isBindCode=useGetCodeByAddress(account)
  const bindCodeCallBack=useBindCodeCallback()
  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()

  const [ischecked, setIschecked] = useState<boolean>(false)
  useEffect(() => {
    if(isBindCode!=="") return
    if (newCode.length > 20 || newCode.length < 6) {
      setIschecked(false) 
      return 
      }

    if (checkcode(newCode)) setIschecked(true)
    else setIschecked(false)

  }, [isBindCode, newCode,checkcode])

  const handleInput = useCallback((event) => {
    const input = event.target.value 
     setNewCode(input)
  }, [])



  const handleSetCode = useCallback(() => {
   
    bindCodeCallBack(newCode) 
    setNewCode('')   
  }, [isBindCode, newCode,bindCodeCallBack  , chainId])
  
  const userLink=useMemo(()=>`https://jingui.pro/?code=${encodeURIComponent(isBindCode)}`,[isBindCode])





  const handleLogout = () => {
    onDismiss?.()
    logout()
  }

  return (
    <>
      <Text color="secondary" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
        {t('Your Address')}
      </Text>
      <CopyAddress tooltipMessage={t('Copied')} account={account} mb="24px" />
      {hasLowNativeBalance && (
        <Message variant="warning" mb="24px">
          <Box>
            <Text fontWeight="bold">
              {t('%currency% Balance Low', {
                currency: native.symbol,
              })}
            </Text>
            <Text as="p">
              {t('You need %currency% for transaction fees.', {
                currency: native.symbol,
              })}
            </Text>
          </Box>
        </Message>
      )}
    <Flex alignItems="center" justifyContent="space-between"> 
      <Text color="secondary" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
        {t('Your Link')}
      </Text>  
      </Flex>  
      {isBindCode && isBindCode!==""? (
        <Box mb="24px">
          <CopyAddress tooltipMessage={t('Copied')} account={userLink} mb="24px" />
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="textSubtle">{t('Your Invited')}</Text>
            {inviteFetchStatus!== FetchStatus.Fetched  ? (
              <Skeleton height="22px" width="60px" />
            ) : (
              <Text>{formatBigNumber(inviteBalance, 0,0)}</Text>
            )} 
          </Flex>
        </Box>
      ):(  
        <Box mb="24px">
          <Flex alignItems="center" justifyContent="space-between">
          <Input
            id="code-input"
            scale="lg"
            placeholder={t('Enter 6-20 characters code')}
            value={newCode}
            autoComplete="off"
            ref={inputRef as RefObject<HTMLInputElement>}
            onChange={handleInput} 
          />
          <Button variant="tertiary" onClick={handleSetCode}
          disabled={!ischecked}
          >
            {t('Set Code')}
          </Button>
          </Flex>  
        
        </Box> 
        
    )}
    {isBindCode==="" && newCode && !ischecked && (
        <Message variant="danger" mb="24px">
          <Box>
            <Text fontWeight="bold">
              {t('Code is not valid')}
            </Text>
            <Text as="p">
              {t('Please enter a valid 6-20 characters code.')}
            </Text>
          </Box>
        </Message>
      )}


 



      {!isBSC && chain && (
        <Box mb="12px">
          <Flex justifyContent="space-between" alignItems="center" mb="8px">
            <Flex bg={COLORS.ETH} borderRadius="16px" pl="4px" pr="8px" py="2px">
              <ChainLogo chainId={chain.id} />
              <Text color="white" ml="4px">
                {chain.name}
              </Text>
            </Flex>
            <LinkExternal href={getBlockExploreLink(account, 'address', chainId)}>
              {getBlockExploreName(chainId)}
            </LinkExternal>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="textSubtle">
              {native.symbol} {t('Balance')}
            </Text>
            {!nativeBalance.isFetched ? (
              <Skeleton height="22px" width="60px" />
            ) : (
              <Text>{formatBigNumber(nativeBalance.data.value, 6)}</Text>
            )}
          </Flex>
          {wNativeBalance.gt(0) && (
            <Flex alignItems="center" justifyContent="space-between">
              <Text color="textSubtle">
                {wNativeToken.symbol} {t('Balance')}
              </Text>
              {wNativeFetchStatus !== FetchStatus.Fetched ? (
                <Skeleton height="22px" width="60px" />
              ) : (
                <Text>{getFullDisplayBalance(wNativeBalance, wNativeToken.decimals, 6)}</Text>
              )}
            </Flex>
          )}
        </Box>
      )}
      <Box mb="24px">
        <Flex justifyContent="space-between" alignItems="center" mb="8px">
          <Flex bg={COLORS.BNB} borderRadius="16px" pl="4px" pr="8px" py="2px">
            <ChainLogo chainId={ChainId.BSC} />
            <Text color="white" ml="4px">
              BNB Smart Chain
            </Text>
          </Flex>
          <LinkExternal isBscScan href={getBlockExploreLink(account, 'address', ChainId.BSC)}>
            {getBlockExploreName(ChainId.BSC)}
          </LinkExternal>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Text color="textSubtle">BNB {t('Balance')}</Text>
          {!bnbBalance.isFetched ? (
            <Skeleton height="22px" width="60px" />
          ) : (
            <Text>{formatBigNumber(bnbBalance.data.value, 6)}</Text>
          )}
        </Flex>
        {wBNBBalance.gt(0) && (
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="textSubtle">WBNB {t('Balance')}</Text>
            {wBNBFetchStatus !== FetchStatus.Fetched ? (
              <Skeleton height="22px" width="60px" />
            ) : (
              <Text>{getFullDisplayBalance(wBNBBalance, wBNBToken.decimals, 6)}</Text>
            )}
          </Flex>
        )}
      </Box> 
      <Button variant="secondary" width="100%" minHeight={48} onClick={handleLogout}>
        {t('Disconnect Wallet')}
      </Button>
    </>
  )
}

export default WalletInfo
