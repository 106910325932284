import { Token, CurrencyAmount } from '@pancakeswap/sdk'
import { useEffect, useMemo, useState } from 'react'
import { INVITE_ADDRESS } from 'config/constants/exchange'
import { useRouter } from 'next/router'
import { useInviteContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'


// 可能的函数名称
export type FunName = 'isBindInviter' | 'getInviter' | 'getCodeState';
     
export function useIsBindInviter(owner: string): boolean | undefined {
  const contract = useInviteContract(false);
  const inputs = useMemo(() => [owner], [owner])
  const result = useSingleCallResult(contract, 'isBindInviter',inputs)
  console.log("useIsBindInviter",result)
  return result?.result?.[0] as boolean ||false 
}
export function useGetInviter(owner: string): string | undefined {
  const contract = useInviteContract(false);
  const inputs = useMemo(() => [owner], [owner]) 
  return  useSingleCallResult(contract, 'getInviter',inputs)?.result?.[0]
}
export function useGetCodeState(code: string): boolean | undefined {
  const contract = useInviteContract(false);
  const inputs = useMemo(() => [code], [code]) 
  const result = useSingleCallResult(contract, 'getCodeState',inputs)
  console.log("getCodeState",result)

  return result?.result?.[0] as boolean
}
export function useCanUseCode(): (code:string) => boolean | undefined {
  const contract = useInviteContract(false);
  const [code,setCode]=useState('')
  const inputs = useMemo(() => [code], [code]) 
  const result = useSingleCallResult(contract, 'getCodeState',inputs)
  return (str:string) => {
    setCode(str)
    console.log("useCanUseCode",result)
    return result?.result?.[0] as boolean || false
  }  
}
 
export function useGetCodeByAddress(owner: string):string| undefined { 
  const contract = useInviteContract(false);
  const inputs = useMemo(() => [owner], [owner]) 
  return  useSingleCallResult(contract, 'getCodeByAddress',inputs)?.result?.[0] || ''

}
 