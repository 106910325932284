import { MaxUint256 } from '@ethersproject/constants'
import { TransactionResponse } from '@ethersproject/providers'
import { useTranslation } from '@pancakeswap/localization'
import { Currency, CurrencyAmount, Trade, TradeType } from '@pancakeswap/sdk'
import { useToast } from '@pancakeswap/uikit'
import { useAccount } from 'wagmi'
import { INVITE_ADDRESS } from 'config/constants/exchange'
import { useCallback, useMemo, useState } from 'react'
import { logError } from 'utils/sentry'
import { Field } from '../state/swap/actions'
import { useHasPendingApproval, useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin } from '../utils'
import { computeSlippageAdjustedAmounts } from '../utils/exchange'
import useGelatoLimitOrdersLib from './limitOrders/useGelatoLimitOrdersLib'
import { useCallWithGasPrice } from './useCallWithGasPrice'
import { useInviteContract } from './useContract'
import useTokenAllowance from './useTokenAllowance'
import { useIsBindInviter,useGetInviter, useGetCodeState } from './useInvite'

export enum BindState {
  UNKNOWN,
  NOT_BIND,
  PENDING,
  BINDED,
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useBindCallback( 
):() => Promise<void> {
  const { address: account } = useAccount()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { t } = useTranslation()
  const { toastError } = useToast()
  const chainId = useGelatoLimitOrdersLib().chainId
  
  const token = INVITE_ADDRESS[chainId]

  const code =localStorage.getItem('inviter_code')
  
  const pendingBind = useHasPendingApproval(token, account)
     

  const tokenContract = useInviteContract()
  const addTransaction = useTransactionAdder()
 
  const approve = useCallback(async (): Promise<void> => {
 
    if (!token) {
      toastError(t('Error'), t('No token'))
      console.error('no token')
      return undefined
    }

    if (!tokenContract) {
      toastError(t('Error'), t('Cannot find contract of the token %tokenAddress%', { tokenAddress: token?.address }))
      console.error('tokenContract is null')
      return undefined
    }
 
    if (!code) {
      toastError(t('Error'), t('No code'))
      console.error('no code')
      return undefined
    }

  
    const estimatedGas = await tokenContract.estimateGas.bindInviter(code).catch((error: any) => {
      console.error('estimate gas failure', error)
      console.error('estimate gas failure')
      toastError(t('Error'), t('Unexpected error. Could not estimate gas for the bind.'))
      return null
    })

    if (!estimatedGas) return undefined

    return callWithGasPrice(
      tokenContract,
      'bindInviter',
      [code],
      {
        gasLimit: calculateGasMargin(estimatedGas),
      },
    )
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `Bind Inviter`,
          translatableSummary: { text: 'Bind Inviter' },
          approval: { tokenAddress: token, spender :code },
          type: 'approve',
        })
      })
      .catch((error: any) => {
        logError(error)
        console.error('Failed to bindInviter ', error)
        if (error?.code !== 4001) {
          toastError(t('Error'), error.message)
        }
        throw error
      })
  }, [ token, tokenContract,  code, addTransaction, callWithGasPrice, t, toastError])

  return approve
}

export function useBindCodeCallback():(newcode:string) => Promise<void> {
  const { address: account } = useAccount()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { t } = useTranslation()
  const { toastError } = useToast()
  const chainId = useGelatoLimitOrdersLib().chainId
  
  const token = INVITE_ADDRESS[chainId]  
     

  const tokenContract = useInviteContract()
  const addTransaction = useTransactionAdder()
 
  const bindCode = useCallback(async (newcode:string): Promise<void> => {
   
    if (!token) {
      toastError(t('Error'), t('No token'))
      console.error('no token')
      return undefined
    }

    if (!tokenContract) {
      toastError(t('Error'), t('Cannot find contract of the token %tokenAddress%', { tokenAddress: token?.address }))
      console.error('tokenContract is null')
      return undefined
    }
 
    if (!newcode) {
      toastError(t('Error'), t('No code'))
      console.error('no code')
      return undefined
    } 

    const estimatedGas = await tokenContract.estimateGas.bindCode(newcode).catch((error: any) => {
      console.error('estimate gas failure', error)
      console.error('estimate gas failure')
      toastError(t('Error'), t('Unexpected error. Could not estimate gas for the bind.'))
      return null
    }) 
    if (!estimatedGas) return undefined
    return callWithGasPrice(
      tokenContract,
      'bindCode',
      [newcode],
      {
        gasLimit: calculateGasMargin(estimatedGas),
      },
    ).then((response: TransactionResponse) => {
      addTransaction(response, {
        summary: `Bind Code`,
        translatableSummary: { text: 'Bind Code' },
        approval: { tokenAddress: token, spender :newcode },
        type: 'approve',
      })
    })
   .catch((error: any) => {
      logError(error)
      console.error('Failed to bindCode ', error)
      if (error?.code !== 4001) {
        toastError(t('Error'), error.message)
      }
      throw error
    })
  }, [ token, tokenContract,   addTransaction, callWithGasPrice, t, toastError])

  return bindCode
    
}

// wraps useApproveCallback in the context of a swap
// export function useApproveCallbackFromTrade(
//   trade?: Trade<Currency, Currency, TradeType>,
//   allowedSlippage = 0,
//   chainId?: number,
// ) {
//   const amountToApprove = useMemo(
//     () => (trade ? computeSlippageAdjustedAmounts(trade, allowedSlippage)[Field.INPUT] : undefined),
//     [trade, allowedSlippage],
//   )

//   return useApproveCallback(amountToApprove, ROUTER_ADDRESS[chainId])
// }

// Wraps useApproveCallback in the context of a Gelato Limit Orders
// export function useApproveCallbackFromInputCurrencyAmount(currencyAmountIn: CurrencyAmount<Currency> | undefined) {
//   const gelatoLibrary = useGelatoLimitOrdersLib()

//   return useApproveCallback(currencyAmountIn, gelatoLibrary?.erc20OrderRouter.address ?? undefined)
// }
